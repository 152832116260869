import React from "react"
import { PostCard } from "./PostCard"

export const ReadNextPostCardFeed = ({ children }) => {
  const columns = children.columns
  const readNextArticles = children.readNextArticles

  return (
    <>
      {readNextArticles.length >= 2 && columns == 2 &&
        <section className="read-more-2-items">
          {readNextArticles.map((node, id)=> (
            <article key={id}>
              <PostCard post={node} />
            </article>
          ))}
        </section>
      }
      {readNextArticles.length >= 2 && columns == 1 &&
        <section>
          {readNextArticles.map((node, id) => (
            <article key={id}>
              <PostCard post={node} />
            </article>
          ))}
        </section>
      }
      {readNextArticles.length == 1 &&
        <article>
          <PostCard post={readNextArticles[0]} />
        </article>
      }
    </>
  )
}