import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import Image from "gatsby-plugin-sanity-image"
import { BlockSerializer } from "../utils/BlockSerializer"
import { ReadNext } from "../components/common/ReadNext"
//import { readingTime as readingTimeHelper } from '@tryghost/helpers'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.sanityPost
    //const readingTime = readingTimeHelper(post) Currently not supported

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                {/*<style type="text/css">{`${post.codeinjection_styles}`}</style>*/}
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>

                            {/* The main post content */ }
                            <div className="content-body">
                                {!post.categories.find(category => category.id == "-c34e0d2b-3e69-5881-a8f7-6209d58cd054") && // "martech tools category"
                                <figure className="post-figure">
                                    <Image
                                        // pass asset, hotspot, and crop fields
                                        {...post.mainImage}
                                        // tell Sanity how large to make the image (does not set any CSS)
                                        width={800}
                                        alt={post.mainImage.alt}
                                        // style it how you want it
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <figcaption>
                                        {post.mainImage.caption} {post.mainImage.author != null && `, © ${post.mainImage.author}`}
                                    </figcaption>
                                </figure>
                                }

                                <BlockSerializer pageSlug={post.slugWithRedirectValidation.slug.current}>{post._rawBody}</BlockSerializer>
                                {post.readNext?.enabled ? <ReadNext>{post.readNext}</ReadNext> : null}
                            </div>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}




Post.propTypes = {
    data: PropTypes.shape({
        sanityPost: PropTypes.shape({
            //codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            mainImage: PropTypes.object,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        sanityPost(slugWithRedirectValidation: { slug: {current: {eq: $slug}}}) {
            ...SanityPostFields
        }
    }
`
